<template>
  <div>
    <loading :active.sync="is_call_api"></loading>
    <v-row justify="center">
      <v-dialog
        v-model="dialogFeedback"
        max-width="1200px"
        scrollable
        persistent
      >
        <v-card>
          <v-card-title>
            <v-col cols="11">
              <span class="headline" v-if="type_form_feedback === 'add'"
                >Thêm phản hồi</span
              >
              <span class="headline" v-else>Sửa phản hồi</span>
            </v-col>
            <v-col cols="1">
              <v-icon @click="dialogFeedback = false" right>mdi-close</v-icon>
            </v-col>
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-row>
                <v-col cols="12">
                  <v-row>
                    <v-col cols="2" class="text-right p-0 m-0"
                      ><label class="text-h6">Số câu đúng</label></v-col
                    >
                    <v-col cols="1" class="p-0 m-0 ml-4"
                      ><v-text-field
                        type="number"
                        dense
                        filled
                        v-model="range_start_input"
                      ></v-text-field
                    ></v-col>
                    <v-icon class="ml-6 mr-6 mb-6"
                      >mdi-arrow-right-bold-outline</v-icon
                    >
                    <v-col cols="1" class="p-0 m-0"
                      ><v-text-field
                        type="number"
                        dense
                        filled
                        v-model="range_end_input"
                      ></v-text-field
                    ></v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="2" class="text-right p-0 m-0"
                      ><label class="text-h6">Ở trình độ nào</label></v-col
                    >
                    <v-col cols="8" class="p-0 m-0 ml-4"
                      ><v-text-field
                        dense
                        filled
                        v-model="display_level_input"
                      ></v-text-field
                    ></v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="2" class="text-right p-0 m-0"
                      ><label class="text-h6">Nhập band điểm</label></v-col
                    >
                    <v-col cols="1" class="p-0 m-0 ml-4"
                      ><v-text-field
                        type="number"
                        dense
                        filled
                        v-model="score_start_input"
                      ></v-text-field
                    ></v-col>
                    <v-icon class="ml-6 mr-6 mb-6"
                      >mdi-arrow-right-bold-outline</v-icon
                    >
                    <v-col cols="1" class="p-0 m-0"
                      ><v-text-field
                        type="number"
                        dense
                        filled
                        v-model="score_end_input"
                      ></v-text-field
                    ></v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="2" class="text-right p-0 m-0 pt-2"
                      ><label class="text-h6">Nhập tag trình độ</label></v-col
                    >
                    <v-col cols="8"
                      ><multiselect
                        v-model="tag_selected"
                        tag-placeholder="Tạo tag này"
                        placeholder="Tìm kiếm hoặc tạo tag"
                        label="name"
                        track-by="id"
                        :options="tags"
                        :multiple="true"
                      ></multiselect
                    ></v-col>
                  </v-row>
                  <v-row class="mt-8">
                    <label class="col-2 text-right text-h6"
                      >Nhập nhận xét</label
                    >
                    <v-col cols="8" class="ml-4 p-0">
                      <!-------------------------------start: tab chuyển đa ngôn ngữ cho data_comment------------------------->
                      <v-card>
                        <v-tabs
                            v-model="data_comment_tab"
                            background-color="blue-grey lighten-1"
                            dark
                        >
                          <v-tab  v-for="(item, key) in data_comment" :key="key">
                            {{ getLabelByLangCode(item.lang) }}
                          </v-tab>
                        </v-tabs>
                      </v-card>
                      <!-------------------------------end: tab chuyển đa ngôn ngữ cho data_comment------------------------->

                      <!-------------------------------start: form nhập nhận xét------------------------->
                      <template v-for="(lang_item, langKey) in data_comment">
                        <div :key="langKey">
                          <template v-if="data_comment_tab === langKey">

                            <v-card>
                              <v-tabs v-model="attribute_comment_tab" background-color="#f0f0f0" dark>
                                <v-tab
                                    v-for="(attribute_item, attributeKey) in lang_item.attributes"
                                    :key="attributeKey"
                                    :style="{ color: attribute_item.type === 1 ? '#00E676' : 'red'}"
                                >
                                  <span class="font-weight-bold">{{ attribute_item.title }}</span>
                                  <v-icon class="d-block ml-1" color="#424242" @click="editAttributeComment(attributeKey)">mdi-file-document-edit-outline</v-icon>
                                  <v-icon class="d-block" color="#424242" @click="deleteAttributeComment(attributeKey)">mdi-close</v-icon>
                                </v-tab>
                                <v-tabs-slider color="#8C9EFF"></v-tabs-slider>
                                <v-icon color="cyan" id="iconAddDataComment" @click="openDialogAttributeComment">mdi-plus</v-icon>
                              </v-tabs>

                              <v-tabs-items
                                  vertical
                                  v-model="attribute_comment_tab"
                                  v-if="lang_item.attributes.length > 0"
                                  style="min-height: 300px"
                              >
                                <v-tab-item
                                    v-for="(item_attribute, attributeKey) in lang_item.attributes"
                                    :key="attributeKey"
                                >
                                  <v-card flat>
                                    <v-row>
                                      <v-col cols="7" class="ml-8 p-0 mt-8">
                                        <v-textarea v-model="comment_input" outlined auto-grow dense rows="1" class="text-body-1"></v-textarea>
                                      </v-col>
                                      <v-btn color="cyan" class="ma-2 mt-8 font-weight-bold" outlined @click="btnAddComment(attributeKey)">
                                        <v-icon small left>mdi-check</v-icon> Lưu
                                      </v-btn>
                                      <v-btn color="#E53935" class="mt-8 font-weight-bold" outlined @click="resetAddComment">
                                        <v-icon small left>mdi-close</v-icon> Xóa nội dung
                                      </v-btn>
                                    </v-row>
                                    <ul>
                                      <template v-for="( comment, iCmt ) in item_attribute.comments">
                                        <li class="d-flex mt-4" :key="iCmt">
                                          <v-btn color="#FFAB00" outlined @click="editComment(attributeKey, iCmt)" small>
                                            <v-icon small>mdi-file-document-edit-outline</v-icon>
                                          </v-btn>
                                          <v-btn color="#D84315" class="ml-1" outlined @click="deleteComment(attributeKey, iCmt)" small>
                                            <v-icon small>mdi-close</v-icon>
                                          </v-btn>
                                          <p class="text-body-1 mb-0 ml-2 text-justify">
                                            {{ comment }}
                                          </p>
                                        </li>
                                      </template>
                                    </ul>
                                  </v-card>
                                </v-tab-item>
                              </v-tabs-items>
                              <v-tabs-items
                                  vertical
                                  v-model="attribute_comment_tab"
                                  v-if="lang_item.attributes.length <= 0"
                                  style="min-height: 250px"
                              >
                                <p style="text-align: center; margin-top: 100px">
                                  No data
                                </p>
                              </v-tabs-items>
                            </v-card>

                          </template>
                        </div>
                      </template>

                      <!-------------------------------end: form nhập nhận xét------------------------->

                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <button
              class="btn btn-light-success text-uppercase mr-2"
              v-if="type_form_feedback === 'add'"
              @click="btnAddOrEditFeedbackDetail(1)"
            >
              Add
            </button>
            <button
              class="btn btn-light-success text-uppercase mr-2"
              v-if="type_form_feedback === 'update'"
              @click="btnAddOrEditFeedbackDetail(2)"
            >
              Update
            </button>
            <button
              class="btn text-uppercase mr-2"
              style="color: #f64e60"
              @click="dialogFeedback = false"
            >
              Close
            </button>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>

    <v-row justify="center">
      <v-dialog
        v-model="dialogAttributeComment"
        max-width="800px"
        scrollable
        persistent
      >
        <v-card>
          <v-card-title>
            <v-col cols="11">
              <span class="headline" v-if="type_form_attribute_comment === 'add'"
                >Thêm tiêu đề nhận xét</span
              >
              <span class="headline" v-else>Sửa tiêu đề nhận xét</span>
            </v-col>
            <v-col cols="1">
              <v-icon @click="dialogAttributeComment = false" right
                >mdi-close</v-icon
              >
            </v-col>
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-row>
                <v-col cols="7" class="pt-0">
                  <v-text-field
                    label="Tiêu đề"
                    v-model="title_attribute_input"
                    required
                  ></v-text-field>
                </v-col>
                <v-col cols="6" class="d-flex pb-0">
                  <label style="font-size: 16px" class="mt-1 p-0">Type: </label>
                  <v-radio-group
                    row
                    class="mt-0 ml-2"
                    v-model="type_attribute_comment_input"
                  >
                    <v-radio label="Positive (màu xanh)" :value="1"></v-radio>
                    <v-radio label="Negative (màu đỏ)" :value="2"></v-radio>
                  </v-radio-group>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <button
              class="btn btn-light-success text-uppercase mr-2"
              @click="btnSaveOrEditAttributeComment"
            >
              Save
            </button>
            <button
              class="btn text-uppercase mr-2"
              style="color: #f64e60"
              @click="dialogAttributeComment = false"
            >
              Close
            </button>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
  </div>
</template>

<script>
import Swal from "sweetalert2";
import "vue-loading-overlay/dist/vue-loading.css";
import ApiService from "@/service/api.service";

export default {
  name: "GeneralForm",
  components: {
    Loading: () => import("vue-loading-overlay"),
  },
  props: {
    show_dialog: {
      type: Boolean,
      default: false,
    },
    type_form_feedback: {
      type: String,
      default: "",
    },
    feedback_general: {},
  },
  data() {
    return {
      is_call_api: false,
      attribute_comment_tab: null,
      feedback_general_id: null,
      data_comment_tab: 0,
      data_comment: [
        {lang: 'vi', attributes: []},
        {lang: 'en', attributes: []},
        {lang: 'th', attributes: []},
      ],
      score_start_input: null,
      score_end_input: null,
      dialogAttributeComment: false,
      type_form_attribute_comment: "",
      comment_input: "",
      title_attribute_input: "",
      type_attribute_comment_input: 1,
      attributeCommentIndex: null,
      tags: [],
      tag_selected: [],
      display_level_input: "",
      range_start_input: null,
      range_end_input: null,
    };
  },
  computed: {
    result_analysis_id: function () {
      return this.$route.params.id;
    },
    dialogFeedback: {
      get() {
        return this.show_dialog;
      },
      set(val) {
        this.$emit("setDialog", val);
      },
    },
  },
  watch: {
    comment_input(val) {
      if (val === "") {
        this.type_form_comment = "";
        this.indexComment = null;
      }
    },
    dialogFeedback(val) {
      if (val) {
        this.setDataForm();
      }
    },
    data_comment_tab() {
      this.attribute_comment_tab = null;
      this.attributeCommentIndex = null;
      this.title_attribute_input = "";
      this.type_form_comment = "add";
      this.indexComment = null;
    },
    attribute_comment_tab() {
      this.type_form_comment = "add";
      this.comment_input = "";
      this.indexComment = null;
    },
  },
  created() {
    this.getAllTag();
  },
  methods: {
    setDataForm() {
      this.data_comment_tab = 0;
      if (this.type_form_feedback === "update") {
        let data = this.feedback_general;
        let tag_json = JSON.stringify(data.tags);
        this.tag_selected = JSON.parse(tag_json);
        this.score_start_input = data.score_start;
        this.score_end_input = data.score_end;
        this.display_level_input = data.display_level;
        let data_comment = JSON.parse(JSON.stringify(data.data_comment));
        this.data_comment = this.transformDataCommentUpdate(data_comment);
        this.range_start_input = data.range_start;
        this.range_end_input = data.range_end;
        this.feedback_general_id = data.id;
      } else {
        this.range_start_input = null;
        this.range_end_input = null;
        this.score_start_input = null;
        this.score_end_input = null;
        this.display_level_input = "";
        this.data_comment = this.defaultDataCommentLang();
        this.tag_selected = [];
      }
      this.resetAddComment();
    },
    async getAllTag() {
      try {
        let res = await ApiService.get("prep-app/tag/limit");
        if (res.status === 200) {
          this.tags = res.data;
        }
      } catch (error) {
        console.log(error);
      }
    },
    openDialogAttributeComment() {
      this.type_attribute_comment_input = 1;
      this.title_attribute_input = "";
      this.type_form_attribute_comment = "add";
      this.attributeCommentIndex = null;
      this.dialogAttributeComment = true;
    },
    btnSaveOrEditAttributeComment() {
      this.title_attribute_input = this.title_attribute_input.trim();
      if (!this.title_attribute_input) {
        this.errorMess("Tiêu đề không được bỏ trống !")
        return;
      }
      if (this.type_form_attribute_comment === "add") {
        let data = {
          title: this.title_attribute_input,
          type: this.type_attribute_comment_input,
          comments: [],
        };
        this.attribute_comment_tab = this.data_comment[this.data_comment_tab].attributes.push(data) - 1;
      } else {
        this.data_comment[this.data_comment_tab].attributes[this.attributeCommentIndex].title = this.title_attribute_input;
        this.data_comment[this.data_comment_tab].attributes[this.attributeCommentIndex].type = this.type_attribute_comment_input;
      }
      this.dialogAttributeComment = false;
    },
    editAttributeComment(attributeIndex) {
      let data = this.data_comment[this.data_comment_tab].attributes[attributeIndex];
      this.attributeCommentIndex = attributeIndex;
      this.title_attribute_input = data.title;
      this.type_attribute_comment_input = data.type;
      this.type_form_attribute_comment = "update";
      this.dialogAttributeComment = true;
    },
    deleteAttributeComment(attributeIndex) {
      let vm = this;
      Swal.fire({
        title: "Are you sure you want to delete?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!",
      }).then((result) => {
        if (result.value) {
          vm.data_comment[vm.data_comment_tab].attributes.splice(attributeIndex, 1);
        }
      });
    },
    btnAddComment(attributeIndex) {
      this.comment_input = this.comment_input.trim();
      if (!this.comment_input) {
        this.errorMess("Nhận xét không được bỏ trống !")
        return;
      }
      if (this.type_form_comment === "update") {
        this.data_comment[this.data_comment_tab].attributes[attributeIndex].comments[this.indexComment] = this.comment_input;
      } else {
        this.data_comment[this.data_comment_tab].attributes[attributeIndex].comments.push(this.comment_input);
      }
      this.type_form_comment = null;
      this.comment_input = "";
    },
    resetAddComment() {
      this.type_form_comment = "";
      this.indexComment = null;
      this.comment_input = "";
    },
    editComment(attributeIndex, indexComment) {
      this.indexComment = indexComment;
      this.comment_input = this.data_comment[this.data_comment_tab].attributes[attributeIndex].comments[indexComment];
      this.type_form_comment = "update";
    },
    deleteComment(indexDataComment, indexComment) {
      let vm = this;
      Swal.fire({
        title: "Are you sure you want to delete?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!",
      }).then((result) => {
        if (result.value) {
          vm.data_comment[indexDataComment].comments.splice(indexComment, 1);
        }
      });
    },
    btnAddOrEditFeedbackDetail(type) {
      if (!this.passGeneralForm()) {
        return;
      }
      let vm = this;
      let data = {
        score_start: this.score_start_input,
        score_end: this.score_end_input,
        data_comment: this.data_comment,
        display_level: this.display_level_input,
        type: 1,
      };
      if (this.range_start_input != null && this.range_start_input !== "") {
        data.range_start = this.range_start_input;
      }
      if (this.range_end_input != null && this.range_end_input !== "") {
        data.range_end = this.range_end_input;
      }
      if (this.tag_selected.length) {
        data.tag_ids = this.tag_selected.map(function ($tag) {
          return $tag["id"];
        });
      }

      if (type === 1) {
        vm.is_call_api = true;
        ApiService.post("prep-app/test-result-analysis/" + this.result_analysis_id + "/feedback", data)
          .then(function (res) {
            vm.$emit("reloadGeneralFeedbackList");
            if (res.status === 200) {
              Swal.fire({
                title: "",
                text: "The application has been successfully submitted!",
                icon: "success",
                confirmButtonClass: "btn btn-secondary",
              });
              vm.is_call_api = false;
              vm.dialogFeedback = false;
            }
          })
          .catch(function (error) {
            vm.is_call_api = false;
            if (error.response.status === 422) {
              let data_response_error = error.response.data.error.message.split(".<br />");
              vm.showError(data_response_error);
            }
          });
      } else {
        vm.is_call_api = true;
        ApiService.put("prep-app/test-result-analysis/" + this.result_analysis_id + "/feedback/" + this.feedback_general_id, data)
          .then(function (res) {
            vm.$emit("reloadGeneralFeedbackList");
            if (res.status === 202) {
              Swal.fire({
                title: "",
                text: "The application has been successfully submitted!",
                icon: "success",
                confirmButtonClass: "btn btn-secondary",
              });
              vm.is_call_api = false;
              vm.dialogFeedback = false;
            }
          })
          .catch(function (error) {
            vm.is_call_api = false;
            if (error.response.status === 422) {
              let data_response_error =
                error.response.data.error.message.split(".<br />");
              vm.showError(data_response_error);
            }
          });
      }
    },
    passGeneralForm() {
      let flat = true;

      // validate data_comment: attributes.length > 0
      this.data_comment.forEach(item => {
        if (item.attributes.length <= 0) {
          this.errorMess("Hãy nhập dữ liệu nhận xét " + this.getLabelByLangCode(item.lang));
          flat = false;
        }
      });

      return flat;
    },
    showError(data_error) {
      for (let i = 0; i < data_error.length; i++) {
        this.errorMess(data_error[i]);
      }
    },
    getLabelByLangCode(lang = '') {
      return this.$utils.getLabelByLangCode(lang);
    },
    defaultDataCommentLang() {
      return [
        {lang: 'vi', attributes: []},
        {lang: 'en', attributes: []},
        {lang: 'th', attributes: []},
      ];
    },
    errorMess(mess = '') {
      this.$toasted.error(mess, {theme: "toasted-primary", position: "top-right", duration: 4000,});
    },
    transformDataCommentUpdate(dataComment) {
      // Nhóm các đối tượng dựa trên thuộc tính 'lang'
      let grouped = dataComment.reduce((result, item) => {
        (result[item['lang']] = result[item['lang']] || []).push(item);
        return result;
      }, {});

      // Chuyển đổi từng nhóm thành định dạng mong muốn
      let dataLang = Object.entries(grouped).map(([lang, attributes]) => ({
        lang,
        attributes: attributes.map(({ id, title, type, comments }) => ({ id, title, type, comments })),
      }));

      // kiem tra xem lang nao chua co thi them vao
      let langDefault = ['vi', 'en', 'th'];
      langDefault.forEach(lang => {
        let isExist = dataLang.find(item => item.lang === lang);
        if (!isExist) {
          dataLang.push({ lang, attributes: [] });
        }
      });
      return dataLang;
    }
  },
};
</script>

<style scoped>
ul,
ol {
  list-style: none;
}
#iconAddDataComment {
  max-width: 200px;
  min-width: 90px;
  text-align: center;
  vertical-align: middle;
  background-color: aliceblue;
}
#iconAddDataComment::after {
  width: 0;
  height: 0;
}
</style>
